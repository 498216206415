$report-padding: 40px;
$report-page-header-height: 100px;
$text-color: black;
$gray: gray;
$gray-200: gray;
$gray-300: gray;
$gray-400: gray;
$gray-500: gray;
$gray-600: gray;
$gray-700: gray;
$gray-800: gray;
$blue: blue;
$white: white;
$cyan: cyan;
$green: green;
$green-alt: green;
$green-yellow: greenyellow;
$green-700: green;

.pdfReportModal  {
	.infoCircle {
		display: none;
	}
}



.report-container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#000, .85);
    z-index: -100000;
    visibility: hidden;
    &.active{
        z-index: 1;
        visibility: visible;
    }

	

    #ReportOutput .html2pdf__page-break{
        height: 20px;
    }

    text, tspan{
        font-family: sans-serif !important;
        color: $text-color;
        fill: $text-color;
    }

    .recharts-label{
        font-size: 10px;
        fill: $gray-600;
    }
}

.landscape-page {
    height: 815px !important;
    .answerSummaryText {
        font-size: 7.5px !important;
    }
}

.report-landscape {
    width: 70% !important;
}

#ReportOutput{    
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    font-size: 14px;

	.infoCircle {
		display: none;
	}

    .report-page {
        background-color: #fff;
        background-size: auto 1200px;
        background-position: 80% bottom;
        background-repeat: no-repeat;
        position: relative;
        height: 1055px;
        padding: $report-padding;
        .report-header{
            max-height: $report-page-header-height;
           h1{
                color: black;
                font-size: 26px;
                margin: 0 0 5px 0;
                width: 100%;
                font-weight: 300;
                padding: 0 20px;
                sup{
                    font-size: 14px;
                }
            }
            h2{
                color: $gray-700;
                font-size: 16px;
                margin: 0;
                width: 100%;
                font-weight: 500;
                padding: 0 20px;
            }                
        }        
        .report-content{
            //position: absolute;
            width: 100%; //calc(100% - #{$report-padding * 2});
            height: calc(100% - #{$report-padding * 2} - #{$report-page-header-height} - 60px);
            top: 0px;
            padding: 20px 20px 20px 20px;
        }

        .report-footer{
            position: absolute;
            bottom: $report-padding;
            font-size: 12px;
            color: $gray-700;
            width: calc(100% - #{$report-padding * 2});
            > span{
                float: right;
            }
        }
    }

    &.hide-page-numbers{
        .report-footer{
            > span{
                display: none;
            }
        }
    }

    .title-page{
        background-size: auto 1200px;
        background-position: center center;        
        .report-content{
            padding: 0;
            h1, h2, h3, h4{                
                font-weight: 400;
            }

            > h4{
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 14px;
            }

            > h1{
                font-size: 38px;
                margin-bottom: 70px;
                font-weight: 300;
                color: black;
            }

            > .branding{
                margin-top: 100px;
                text-align: center;
                h2{
                    font-size: 16px;
                    font-weight: 500;
                    color: $gray-600;
                }
                h3{
                    font-size: 36px;
                    margin-bottom: 30px;
                    font-weight: 500;
                    color: $green;
                }
                img{
                    max-height: 300px;
                    max-width: 400px;
                }
            }

            > .byline{
                position: absolute;
                bottom: 100px;
                h2{
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 20px;
                }
                h3{
                    font-size: 24px;
                    margin-bottom: 30px;
                    font-weight: 500;
                }
                h5{
                    font-size: 16px;
                    font-weight: bold; 
                    margin-top: 80px;
                    margin-bottom: 20px;
                }
                h6{
                    font-size: 14px;
                    font-weight: 400; 
                }
                img{
                    max-height: 75px;
                    max-width: 150px;
                }
            }
        }
    }

    .functions {
        .report-content{
            > div{
                margin-top: 30px;
                color: $cyan;
                font-weight: bold;
                p{
                    display: block;
                    font-size: 18px;
                    span{
                        float: right;
                    }
                }
            }
        }
    }
}

.logo {
	padding-left: 10px;
	padding-right: 10px;
	img {
		width: 150px;
	}
}

.grayDivider {
	border-left: 1px solid gray;
}


.pdfReport {
    width: 900px;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    overflow: auto;

    .report-header {
        min-height: 50px;
		max-height: $report-page-header-height;
		overflow: hidden;
    }

    .page-height {
        height: 615px;
    }

    .spacer {
        margin-top: 20px;
    }
    .hours-billed__container {
        max-height: 615px!important;
        
        width: 900px;
        
        overflow:hidden;
    }    
    
    .p-container {
        max-height: 615px;
    }

    .first-page__container {
        height: 615px;
        width: 900px;
        

    

        .vertical-line {
            margin-left: 263px;
            margin-top: 170px;
            border-left: 3px solid #f0f0f2;
            height: 330px;
            margin-bottom: 20px;
        }

        .first-page__logo {
            float: left;
            margin-top: 220px;
        }
        .first-page__logo img {
            height: 150px;
            width: 250px;
        }

        .first-page-text__container {
            margin-top: 180px;
            display: flex;
            height: 280px;
            flex-direction: column;
            justify-content: space-between;
            width: 600px;
            float: right;

            .first-page__year {
                font-size: 19px;
            }

            .first-page__title {
                font-size: 60px;
            }
            .first-page__copy {
                font-size: 19px;
            }
        }
    }

    .hours-billed {
        height: 615px;
    }

    .p-hours-billed {
        margin-top: 10px;
    }
    .p-datatable-margin {
        margin-top: -30px;
    }
    .footer-staff {
        margin-top: -30px;
    }

    .flex-footer {
        width: 100%;
        // margin-top: 120px;
        height: 150px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .product-title {
            font-size: 22px;
            color: #a5aca8;
            margin-right: 345px;
            margin-bottom: 30px;
        }

        .vertical-line {
            border-left: 2px solid #e6e6e6;
            height: 50px;
            margin-bottom: 10px;
        }

        .logo,
        .logo img {
            width: 150px;
            height: 60px;
            margin: 10px 10px 20px 10px;
        }
        .logo-zen img {
            margin-top: 30px;
            width: 130px;
            height: 20px;
            margin-bottom: -10px;
        }
    }

    .report-content {
        width: 100%;
        height: calc(100% - #{40px * 2} - #{90px} - 60px);
        top: 0px;
        padding: 0px 20px 0px 20px;

        .p-g-1 {
            margin-top: 100px;
            margin-bottom: 50px !important;
        }
    }

    .card {
        margin-right: 20px;
        box-shadow: none;
        border: 1px solid #eeeeee;

        .title {
            font-size: 18px;
            padding-bottom: 12px;
        }

        .value {
            font-size: 18px;
            font-weight: 700;
        }

        .subTitle {
            color: $zenSubtitleGray;
        }

        .subTitlePercentage {
            color: $zenColor;
        }

        .totalBudget {
            font-size: 30px;
            padding-top: 21px;
        }
		.titleAnnual {
            font-size: 16px;
        }

		.totalBudgetAnnual {
			font-size: 18px;
		}
    }

    .card.blueCard {
        border-left: 4px solid $zenBlue;
    }

    .card.chartCard {
        height: 262px;

        .centerText {
            position: relative;
            font-size: 12px;
            top: -49%;
            display: flex;
            justify-content: center;
            text-align: center;

            .percent {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }

    .p-sortable-disabled {
        height: 40px !important;
        width: 90px !important;
        font-size: 15px;
		padding: 0.5rem 1rem !important;
    }
    td {
        font-size: 12px;
        padding: 0.5rem 1rem !important;
    }

	.artCharts-budgetDetails {
		width: 900px;
	}
	
	.insightText {
		width: 900px;
		overflow: hidden;
	
		.wrapper1 {
			column-width: 900px;
			max-height: 72px;
		}
	}
}

