@import './constants/colors.scss';

$greenIndicatorColor: rgb(73, 160, 120) !default;
$greenBackgroundColor: rgba(73, 160, 120, 0.1) !default;
$redIndicatorColor: rgb(219, 84, 97) !default;
$redBackgroundColor: rgba(219, 84, 97, 0.1) !default;
$orangeIndicatorColor: rgb(255, 147, 79) !default;
$orangeBackgroundColor: rgba(255, 147, 79, 0.1) !default;
$overSlider: rgb(219, 84, 97) !default;
$underSlider: rgb(11, 96, 160) !default;
$smallTitle: rgb(16, 95, 181) !default;

$zenBlue: #005fa2 !default;
$zenSubtitleGray: #4c5951 !default;
$zenColor: #1f2421 !default;
$grayBackground: rgba(209, 209, 215, 0.2) !default;


$budgetColor: $BUDGET_LIGHT_COLOR;
$budgetColorDark: $BUDGET_DARK_COLOR;
$budgetColorFaded: $BUDGET_LIGHT_COLOR_FADED;
$staffColor: $STAFF_LIGHT_COLOR;
$staffColorDark: $STAFF_DARK_COLOR;
$oopColor: $OOP_LIGHT_COLOR;
$oopColorDark: $OOP_DARK_COLOR;

.changedCell {
	border: 1px solid $redIndicatorColor !important;
	background: $redBackgroundColor;
}


.budgetHelp {
	.infoBody {
		border-top: 3px solid $budgetColor;
		padding-top: 5px;
	}
	.emphasisColor {
		color: $budgetColor;
		font-weight: 800;
	}
}

.staffHelp {
	.infoBody {
		border-top: 3px solid $staffColor;
		padding-top: 5px;
	}
	.emphasisColor {
		color: $staffColor;
		font-weight: 800;
	}
}

.oopHelp {
	.infoBody {
		border-top: 3px solid $oopColor;
		padding-top: 5px;
	}
	.emphasisColor {
		color: $oopColor;
		font-weight: 800;
	}
}


.strong {
    font-weight: 700;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.green {
    color: $greenIndicatorColor;
}

.red {
    color: $redIndicatorColor;
}

.orange {
    color: $orangeIndicatorColor;
}

.progressBarGreen {
    .p-progressbar-value {
        background-color: $greenIndicatorColor;
    }
	.p-progressbar-label {
		color: white;
	}
}

.progressBarRed {
    .p-progressbar-value {
        background-color: $redIndicatorColor;
    }
	
}

.progressBarOrange {
   
	.p-progressbar-value {
        background-color: $orangeIndicatorColor;
    }
	.p-progressbar-label {
		color: white;
	}
}

.progressBarOver {
    .p-progressbar-value {
        background-color: $overSlider;
    }
}

.progressBarUnder {
    .p-progressbar-value {
        background-color: $underSlider;
    }
}

.progressBarBrandOverview {
    height: 0.75rem;
    .p-progressbar-value {
        background-color: $underSlider;
    }
}

.subTable {
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 15px;
        background-color: #eeeeee;
    }

    .p-datatable-thead {
        display: none;
    }
}

.p-datatable-thead {
	.p-inputtext {
		font-size: 12px;
	}
}

.hidden {
	display: none;
}

.tinyTitle {
    color: $smallTitle;
    font-size: 10px;
}

.tinyPercent {
     font-size: 14px;
}

.smallerDetails {
	color: $smallTitle;
	font-size: 12px;
}

.link {
    color: $linkColor;
    cursor: pointer;
}

.customChevron {
    display: inline-block;
}

.hoursScroller .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.nowrap {
	text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.hoursScroller .p-scrollpanel-bar {
    background-color: $zenBlue;
    opacity: 1;
    transition: background-color 0.3s;
}

.hoursScroller .p-scrollpanel-bar:hover {
    background-color: $zenBlue;
}

.modalMessage {
    position: absolute;
    top: 0;
    right: 0;
}

.brandSummary, .resourceMix {
    .card {
        margin-right: 20px;

        .title {
            font-size: 21px;
            padding-bottom: 6px;
        }

        .value {
            font-size: 18px;
            font-weight: 700;
        }

        .subTitle {
            color: $zenSubtitleGray;
        }

        .subTitlePercentage {
            color: $zenColor;
        }

        .totalBudget {
            font-size: 30px;
            padding-top: 21px;
        }

		.titleAnnual {
            font-size: 16px;
        }

		.totalBudgetAnnual {
			font-size: 18px;
		}
    }

    .card.blueCard {
        border-left: 4px solid $zenBlue;
    }

    .card.chartCard {
        height: 390px;

        .centerText {
            position: relative;
            font-size: 12px;
            top: -120px;
            display: flex;
            justify-content: center;
            text-align: center;

            .percent {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }

    .row1 {
        .card {
            height: 283px;
        }
    }

    .shortRow {
        width: 33.34%;
        padding-bottom: 10px;
        text-align: center;
    }
}





.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-50 {
    width: 50%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;

	ul.p-chips-multiple-container {
		width: 100%;
	}
}

.capitalize {
	text-transform: capitalize;
}

.w-100 {
    width: 100%;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-align: center;
    background: #eeeeee;
    color: black;
}

.status-pending,
.status-leave,
.status-medium {
    background: $orangeBackgroundColor;
    color: $orangeIndicatorColor;
}

.status-active,
.status-low {
    background: $greenBackgroundColor;
    color: $greenIndicatorColor;
}

.status-canceled,
.status-inactive,
.status-high {
    background: $redBackgroundColor;
    color: $redIndicatorColor;
}

.projectImport {
    .p-datatable-projects {
		font-size: 12px;

		.name {
			font-size: 14px;
			font-weight: 700;
		}

		.description {
			font-size: 12px;
		}
	}
}

.projectList {
    .p-datatable-projects {
        font-size: 12px;

        .p-datatable-tbody td {
            height: 150px !important;
        }
    }

	.p-datatable-staff {
        font-size: 12px;

        .p-datatable-tbody td {
            height: 170px !important;
        }
    }

    .name {
        font-size: 16px;
        font-weight: 700;
    }

    .description {
        font-size: 12px;
    }

    .brand {
        font-size: 12px;
        font-weight: 700;
    }
}

.staffDetails {
    .name {
        font-size: 16px;
        font-weight: 700;
    }
    .brand {
        font-size: 12px;
        font-weight: 700;
    }
}

.changesModal {
	width: 50vw;
	height: 70vh;

	.name {
        font-size: 14px;
        font-weight: 700;
    }

	.dateRange {
		font-size: 11px;
	}

	.comments {
		font-size: 11px;
		color: red;
	}
}

.tableHeading {
    font-size: 18px;
    font-weight: 700;
}

.addStaffModal {
    width: 60vw;
	height: 70vh;
}

.importModal {
	max-width: 80vw;
	min-width: 40vw;
	height: 70vh;
}

.grayBG {
    background: $grayBackground;
    padding: 3px;
    font-weight: 700;
}

.transferHoursModal {
    width: 1000px;
}

.addHoursModal {
    width: 700px;

    .currentStaff {
        .name {
            font-size: 14px;
            font-weight: 700;

            i {
                padding-left: 5px;
            }
        }

        .title {
            font-size: 12px;
        }

        .rate {
            font-size: 12px;
        }
    }

    .hoursTable {
        .p-datatable-thead tr th {
            padding-top: 2px !important;
            padding-bottom: 2px !important;
        }

        .p-datatable-tbody td {
            padding-top: 2px !important;
            padding-bottom: 2px !important;
            font-size: 12px;
        }
    }

    .addHours {
        color: $greenIndicatorColor;
        font-weight: 700;
    }

    .removeHours {
        color: $redIndicatorColor;
        font-weight: 700;
    }
}

.verticalDividerLeft {
    border-left: 1px solid $borderColor;
}

.horizontalDividerBottom {
    border-bottom: 1px solid $borderColor;
}

.exportOptionsModal {
    width: 600px;
    height: 530px;

    .selectButtons {
        .p-button {
            width: 120px;
        }
    }

    .title {
        font-size: 18px;
        padding-bottom: 12px;
        font-weight: 700;
    }
}

.pdfReportModal {
    width: 1375px;
}

.darkMask.p-dialog-mask.p-component-overlay {
    background: rgba(0, 0, 0, 0.9);
}

.projectDetails  {
	.title {
        font-weight: 700;
    }

	.description {
        font-size: 12px;
    }
}

.projectModal {
    width: 60vw;
    height: 790px;

    .steps {
        padding-bottom: 25px;
    }

    ul.staffList {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .title {
        font-weight: 700;
    }

	.description {
        font-size: 12px;
    }

	.highlightrows {
		
		> div {
			padding: 5px;
		}
		> div:nth-child(odd) {background: #EEEEEE}
	}

    .staffWrapper {
        .staff {
            border-left: 2px solid gray;
            margin-bottom: 5px;
            padding-left: 5px;

            .name {
                font-weight: 700;

                .pi {
                    padding-left: 5px;
                    font-size: 11px;
                }
            }

            .budget {
                font-size: 12px;
            }
        }

        .remove {
            cursor: pointer;
            .pi {
                font-size: 12px;
                color: red;
            }
        }

        .staff.staffStatus_active {
            border-color: green;
        }

        .staff.staffStatus_inactive {
            border-color: red;
        }
    }

    .staffDiv {
        border-right: #cccccc 2px solid;
        padding: 5px;
    }

    .addStaffDiv {
        padding-left: 10px;
    }
}

.projectDetails, .resourceMix  {
    .detailsSection {
        padding: 20px;
    }

    .card {
        margin-bottom: 2rem;
        font-weight: 700;
    }
}

.artCharts-doughnut {
    position: relative;
    width: 500px;
}
 
.doughnutHalf {
	position: relative;
	top: -170px;
	height: 32vh;
}

.doughnutHalfLoading {
	position: relative;
	height: 32vh;
}

@media (max-width: 1700px) {
	.doughnutHalf {
		position: relative;
		top: -160px;
		height: 32vh;
	}
}

@media (max-width: 1500px) {
	.doughnutHalf {
		position: relative;
		top: -135px;
		height: 32vh;
	}
}

@media (max-width: 1280px) {
    
	.doughnutHalf {
		position: relative;
		top: -100px;
		height: 32vh;
	}
}

@media (max-width: 1024px) {
    
	.doughnutHalf {
		position: relative;
		top: -100px;
		height: 27vh;
	}
}

.artCharts-bar {
    position: relative;
    width: 100%;
}



.artCharts-totalBilledHours {
    width: 200px;
}

.p-breadcrumb {
    padding-top: 1px;
    padding-bottom: 2px;
}

.datePicker {
    padding: 10px;
    background: white;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    margin-right: 10px;
}

.briefcasemarg {
    margin-left: 5px;
}

.forgetPassword {
	padding-top: 20px;
	cursor: pointer;
	color: $linkColor;
	font-size: 12px;
}

.forgetPasswordTitle {
	font-size: 16px;
	padding-bottom: 12px;
	font-weight: 700;
}

.pageLoading {
	font-size: 10em;
	color: $zenBlue;
}

.pageLoadingBudget {
	font-size: 16em;
	color: $budgetColor;
}

.pageLoadingStaff {
	font-size: 16em;
	color: $staffColor;
}

.pageLoadingOOP {
	font-size: 16em;
	color: $oopColor;
}

.saveIcon {
	color: $redIndicatorColor;
	cursor: pointer;
	padding-left: 5px;
}

.editIcon {
	color: $orangeIndicatorColor;
	cursor: pointer;
	padding-left: 5px;
}

.confirmModal {
	width: 40vw;
}

.infoCircle {
	padding-right: 5px;
	padding-left: 5px;
	color: $orangeIndicatorColor;
	font-size: 16px;
	cursor: pointer;
}

.infoModal {
	width: 50vw;
}

.infoModalWide {
	width: 80vw;
}

.projectSpendModal {
	width: 75vw;
	height: 90vh;
}

.infoModal, .infoModalWide {
	.infoTitle {
		font-size: 16px;
		font-weight: 700;
	}

	.infoBody {
		font-size: 14px;
	}

	.infoBodyQuestion {
		font-size: 12px;
		color: red;
	}

	.infoBodyTable {
		font-size: 12px;

		> div {
			padding-bottom: 5px;
			border-bottom: 1px solid $grayBackground;
		}
	}
}

.talentWidth {
	width: 15%;
}


@media (max-width: 1280px) {
    
	.brandSummary, .resourceMix   {
        .card {
			.title {
				font-size: 18px;
				padding-bottom: 2px;
			}
			.totalBudget {
                font-size: 24px;
            }

			.totalBudgetAnnual {
                font-size: 14px;
            }

			.titleAnnual {
				font-size: 12px;
			}
        }

        .card.chartCard .centerText {
            top: -100px;
        }
    }

    .artCharts-totalBilledHours {
        width: 160px;
    }

	.projectModal {
		width: 95vw;
	}

	.pdfReportModal {
		width: 90vw;
	}
}




.newOverview {

	.fullWidget {width: 100%}
	.halfWidget {width: 50%}
	.thirdWidget {width: 33.34%}
	.twoThirdWidget {width: 66.7%}
}

@media (max-width: 1200px) {
    .brandSummary, .resourceMix  {
        .card {
            .totalBudget {
                font-size: 20px;
            }
        }
    }

	.infoModal {
		width: 80vw;
	}	
}


.desktopWidget {
	padding: 5px;
	width: 100%;
	height: 100%;

	.titleBar {
		height: 50px;
	}

	.title {
		font-size: 20px;
		font-weight: 700;
		padding-top: 5px;
	}

	.subtitle {
		font-size: 14px;
		font-weight: 700;
		padding-top: 5px;
	}

	.legendSquare {
		width: 20px;
		height: 20px;
	}
}


.desktopWidget.budgetActive {
	box-shadow: 0 8px 2px -2px $budgetColor;
}

.desktopWidget.staffActive {
	box-shadow: 0 8px 2px -2px $staffColor;
}

.desktopWidget.oopActive {
	box-shadow: 0 8px 2px -2px $oopColor;
}

.newOverview {
	
	.keyChanges {
		font-weight: 700;
		text-align: center;
		font-size: 18px;

		.staffChanges {
			color: $staffColor;
		}

		.projectChanges {
			color: $PROJECT_CHANGES;
		}

		.budgetChanges {
			color: $budgetColor;
		}
	}
	.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
		background: $staffColorDark;
	}

	.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
		background: $staffColorDark;
	}

	
	
	
	
	.tables {
		font-size: 14px;
		font-weight: 700;

		.p-datatable-thead > tr > th, .p-datatable-tfoot > tr > th{
			font-weight: 700;
		}

		.p-datatable-thead > tr > th {
			border-width: 1px;
			color: white;
		}
	}

	.spend {
		.p-datatable-thead  > tr > th {
			background: $budgetColor;
		}

		.p-datatable-tbody > tr > td {
			border-width: 1px;
		}

		.p-datatable-tfoot > tr > td {
			background: $budgetColorDark;
		}
	}

	.staff {
		.p-datatable-thead  > tr > th {
			background: $staffColor;
		}

		.p-datatable-tbody > tr > td {
			border-width: 1px;
		}

		.p-datatable-tfoot > tr > td {
			background: $staffColorDark;
		}
	}

	.oop {
		.p-datatable-thead  > tr > th {
			background: $oopColor;
		}

		.p-datatable-tbody > tr > td {
			border-width: 1px;
		}

		.p-datatable-tfoot > tr > td {
			background: $oopColorDark;
		}
	}
}

.topNavDropdownLabel {
	font-weight: 700;
	font-size: 11px;
}


.hoursCard {
	width: 33.34%;
	padding-bottom: 5px;
	text-align: center;	
	border:1px solid $budgetColor;

	.title {
		font-weight: 600;
	}
}

.downloadIcon {
	cursor: pointer;
	padding-left: 5px;
	i {
		font-size: 16px;
		color: #888888;
	}
}




.p-datatable .p-column-header-content {
	display: block;
}

.priority {
	color: red;
	cursor: pointer;
	i {
		font-size: 20px;
		
	}
}

.redFlagColumn {
	color: red;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
	background: $BUDGET_LIGHT_COLOR_FADED_MORE;
}

.newOverview {
	.p-column-filter {
		width: 100%;
	}
	
	.p-datatable .p-sortable-column .p-sortable-column-icon {
		color: white;
	}
	
	.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
		color: white;
	}

	.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
		background: $budgetColorFaded;
	}
}

